<template>
  <div>
    <div v-if="fileNumber">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-chip small outlined style="font-size: 11px; border:none" v-on="on">
          <v-icon :color="statusColor(status)" small left>
            fiber_manual_record
          </v-icon>
          {{ fileNumber }}
        </v-chip>
      </template>
      <span style="font-size: 12px">{{ status }}</span>
    </v-tooltip>
    
  </div>
  <div v-else-if="group">
    <span class="ml-1">{{ group }}</span>
  </div>
  </div>
 
</template>

<script>
export default {
  data: () => ({
    item: null,
    fileNumber: null,
    group: null,
    status: null,
    statuses: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Ready", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Processed", icon: "warning", color: "success" },
    ],
  }),
  mounted() {
    this.item = this.params.value;
    this.status = this.params.status
    this.fileNumber = this.params.fileNumber;
    if(this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
  methods: {
    statusColor(status) {
      let color = this.statuses.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.statuses.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
  }
};
</script>

<style>
</style>