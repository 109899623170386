<template>
  <div v-if="item">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-avatar
          size="24"
          v-on="on"
          :color="
            item == 'EXPORT'
              ? 'deep-orange'
              : item == 'IMPORT'
              ? 'blue'
              : 'green'
          "
        >
          <h4 style="color: white">
            {{ item.charAt(0) }}
          </h4>
        </v-avatar>
      </template>
      <span style="font-size: 12px">{{
        $Format.capitalizeFirstLetter(item)
      }}</span>
    </v-tooltip>
    <span v-if="group" class="ml-1">{{ group }}</span>

  </div>
</template>

<script>
export default {
  data: () => ({
    group: null,
    item: null,
  }),
  mounted() {
    this.item = this.params.value;
    if(this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
};
</script>

<style>
</style>