<template>
  <div v-if="item">
    <v-tooltip top open-delay="500">
      <template v-slot:activator="{ on }">
        <v-chip small outlined style="font-size: 11px; border:none">
          <v-icon :color="statusColor(item)" v-on="on" small left>
            fiber_manual_record
          </v-icon>
          {{ getTextValue(item) }} 
        </v-chip>
      </template>
      <span style="font-size: 12px">{{ getTextValue(item) }} <span v-if="allocatedPallets">: {{allocatedPallets}} Allocated Pallets</span></span>
    </v-tooltip>
  </div>
</template>
  
  <script>
export default {
  data: () => ({
    allocatedPallets: 0,
    item: null,
    group: null,
    statuses: [
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
      { name: "Instruction Cancelled", icon: "warning", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
  }),
  mounted() {
    this.item = this.params.value;
    this.allocatedPallets = this.params.data ? this.params.data.allocatedPallets : null;
  },
  methods: {
    getTextValue(status) {
      if (status) {
      return status
      } else {
        return "Unknown";
      }
    },
    statusColor(item) {
      if (item) {
        let find = this.statuses.find(
          (x) => x.name.toLowerCase() === item.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
  },
};
</script>
  
  <style>
</style>