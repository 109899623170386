<template>
  <div v-if="booking">
    <v-toolbar flat dense>
      <v-spacer></v-spacer>

      <!-- <v-tooltip top v-if="selectedRows.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn
            @click="massAction('edit')"
            v-on="on"
            rounded
            outlined
            small
            class="mx-1"
            icon
            color="amber darken-2"
            ><v-icon small>edit</v-icon></v-btn
          >
        </template>
        <span>Edit Selection</span>
      </v-tooltip>

      <v-tooltip top v-if="selectedRows.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn
            @click="massAction('split_booking')"
            v-on="on"
            rounded
            outlined
            small
            class="mx-1"
            color="blue"
            icon
            ><v-icon small>call_split</v-icon></v-btn
          >
        </template>
        <span>Split Selection</span>
      </v-tooltip>

      <v-tooltip top v-if="selectedRows.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn
            @click="massAction('mark_ghost')"
            v-on="on"
            rounded
            outlined
            small
            icon
            class="mx-1"
            color="blue-grey"
            ><v-icon small>category</v-icon></v-btn
          >
        </template>
        <span>Set as Ghost</span>
      </v-tooltip>

      <v-tooltip top v-if="selectedRows.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn
            @click="massAction('link_to_file')"
            v-on="on"
            rounded
            outlined
            small
            icon
            class="mx-1"
            color="teal"
            ><v-icon small>link</v-icon></v-btn
          >
        </template>
        <span>Link to File</span>
      </v-tooltip>

      <v-tooltip top v-if="selectedRows.length > 0">
        <template v-slot:activator="{ on }">
          <v-btn
            @click="massAction('remove')"
            v-on="on"
            rounded
            outlined
            icon
            small
            class="mx-1"
            color="red"
            ><v-icon small>cancel</v-icon></v-btn
          >
        </template>
        <span>Remove</span>
      </v-tooltip>
      <v-spacer></v-spacer> -->
      <v-chip class="mr-1 searchChipWrap" style="max-width: 550px">
                      <v-tooltip top open-delay="500">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon left small @click="addContainerDetails()" color="primary"><v-icon>add_circle_outline</v-icon></v-btn>
                            </template>
                            Add Container
                          </v-tooltip>
        <v-icon small color="grey" class="ml-1">search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1 searchInput"
          hide-details
          rounded
          clearable
          dense
          style="margin-top: 2px"
          v-model="search"
        ></v-text-field>

        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              right
              small
              color="blue"
              @click="$emit('refreshContainers')"
              v-on="on"
            >
              <v-icon>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Containers</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitCellContents',
        defaultMinWidth: 150,
        columnLimits: [
          {
            colId: 'products',
            minWidth: 200,
          },
        ],
      }"
      style="width: 100%; height: 85vh"
      :rowSelection="'multiple'"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :getContextMenuItems="getContextMenuItems"
      :columnDefs="columns"
      :rowData="booking.bookingContainers"
      :suppressAggFuncInHeader="true"
      :initialState="initialState"
      :defaultColDef="defaultColDef.def"
      :statusBar="statusBar"
      :undoRedoCellEditing="true"
      :undoRedoCellEditingLimit="25"
      :editType="'fullRow'"
      :sideBar="true"
      :getRowId="getRowId"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>

    <v-dialog v-model="viewContainerModal" fullscreen>
      <ViewContainer
        @close="(viewContainerModal = false), (container = null)"
        :booking="booking"
        :container="container"
        :containerTypes="containerTypes"
        :modalOpen="viewContainerModal"
      />
    </v-dialog>

    <v-dialog
      v-model="confirmContainerCancel"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmContainerCancel = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to cancel
            <span v-if="containerRemovalType == 'single'">
              this container?</span
            >
            <span v-else> these containers?</span>
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmContainerCancel = false"
              >No</v-btn
            >
            <v-btn
              v-if="containerRemovalType == 'single'"
              color="success"
              class="mx-2"
              text
              small
              @click="deleteContainer(container)"
              :loading="loadingCancel"
              >Yes</v-btn
            >
            <v-btn
              v-else-if="containerRemovalType == 'multiple'"
              color="success"
              class="mx-2"
              text
              small
              @click="deleteMultipleContainers()"
              :loading="loadingCancel"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="fileModal"
      width="500px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingFiles" v-if="fileItem">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Link to File </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="fileModal = false">X</v-btn>
        </v-toolbar>
        <v-card-subtitle v-if="fileItem.ctoNo">
          <v-icon class="mr-1 pb-1" color="grey">widgets</v-icon> CTO No:
          {{ fileItem.ctoNo }}
        </v-card-subtitle>
        <v-card-subtitle v-if="fileItem.shipmentFile">
          <v-icon class="mr-1 pb-1" color="grey">description</v-icon> Current
          File: {{ fileItem.shipmentFile.fileNumber }}
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            v-model="searchFile"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterAvailableFiles.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px">No files found</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="file in filterAvailableFiles"
              :key="file.id"
              @click="
                fileItem.type == 'single'
                  ? setContainerFile(file)
                  : setContainerMultipleFile(file)
              "
              style="border-bottom: 0.5px solid grey"
            >
              <v-list-item-content>
                <v-list-item-title>{{ file.fileNumber }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bookingSplitTypeModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Split Booking</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="bookingSplitTypeModal = false" color="redPop"
            ><v-icon small>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <v-card
                style="border-radius: 20px; background: #2196f3 !important"
                @click="
                  (bookingCreateConfirm = true), (bookingSplitTypeModal = false)
                "
              >
                <v-card-text>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>add_circle_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Create New Booking
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                style="border-radius: 20px; background: #607d8b !important"
                @click="
                  moveContainer('multiple'), (bookingSplitTypeModal = false)
                "
              >
                <v-card-text>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>input</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Move to existing booking
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bookingCreateConfirm"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="creatingNewBooking">
        <v-toolbar flat color="transparent">
          <v-btn
            icon
            @click="
              (bookingCreateConfirm = false), (bookingSplitTypeModal = true)
            "
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to create a new booking for
            <span v-if="moveContainerType == 'single'"> this container?</span>
            <span v-else> the selected containers?</span>
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="bookingCreateConfirm = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="createBookingMultipleContainer()"
              :loading="creatingNewBooking"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="bookingMoveError"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text class="text-left mt-3 pt-3">
          <p style="font-size: 16px">
            Please select make sure to complete the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.shipper">Select a Customer</li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="bookingMoveError = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="moveContainerModal"
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingCustomerBooking">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-btn
              class="mr-2"
              icon
              @click="
                (moveContainerModal = false), (bookingSplitTypeModal = true)
              "
            >
              <v-icon>arrow_back</v-icon>
            </v-btn>
            Move Container
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="moveContainerModal = false">X</v-btn>
        </v-toolbar>
        <v-card-subtitle v-if="container"
          ><v-icon class="mr-1 pb-1" color="grey"> widgets</v-icon>
          {{ container.ctoNo }}</v-card-subtitle
        >
        <v-card-text>
          <v-text-field
            placeholder="Search"
            v-model="searchBooking"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item v-if="filterAvailableBookings.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No bookings found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="item in filterAvailableBookings"
              :key="item.id"
              @click="moveContainerConfirm(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.orderNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.shipper">
                  <v-icon class="mr-1 pb-1" color="grey" small>groups</v-icon>
                  {{ item.shipper.name }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="item.consignee">
                  <v-icon class="mr-1 pb-1" color="grey" small
                    >call_received</v-icon
                  >
                  {{ item.consignee.name }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="item.vessel">
                  <v-icon class="mr-1 pb-1" color="grey" small
                    >directions_boat</v-icon
                  >
                  {{ item.vessel.name }}
                  {{ item.mainCarriageConveyanceNumber }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-if="item.portOfLoadValue && item.finalDestinationValue"
                >
                  <v-chip pill small v-if="item.portOfLoadValue">
                    <v-avatar size="14" left>
                      <v-img
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ item.portOfLoadValue }}
                  </v-chip>
                  <v-chip
                    pill
                    small
                    v-if="item.finalDestinationValue"
                    class="mx-1"
                  >
                    <v-avatar size="14" left>
                      <v-img
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ item.finalDestinationValue }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip>{{ item.numContainers }}</v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Move container Dialog -->
    <v-dialog
      v-model="bookingMoveConfirm"
      width="600px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn icon color="redPop" @click="bookingMoveConfirm = false">
                      <v-icon small >close</v-icon>
                    </v-btn>
                </v-toolbar>
        <v-card-text>
          <v-list-item v-if="moveItem">
            <v-list-item-content>
              <v-list-item-title>{{ moveItem.orderNo }}</v-list-item-title>
              <v-list-item-subtitle v-if="moveItem.shipper">
                <v-icon class="mr-1 pb-1" color="grey" small>groups</v-icon>
                {{ moveItem.shipper.name }}</v-list-item-subtitle
              >
              <v-list-item-subtitle v-if="moveItem.consignee">
                <v-icon class="mr-1 pb-1" color="grey" small
                  >call_received</v-icon
                >
                {{ moveItem.consignee.name }}</v-list-item-subtitle
              >
              <v-list-item-subtitle v-if="moveItem.vessel">
                <v-icon class="mr-1 pb-1" color="grey" small
                  >directions_boat</v-icon
                >
                {{ moveItem.vessel.name }}
                {{
                  moveItem.mainCarriageConveyanceNumber
                }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                v-if="
                  moveItem.portOfLoadValue && moveItem.finalDestinationValue
                "
              >
                <v-chip pill small v-if="moveItem.portOfLoadValue">
                  <v-avatar size="14" left>
                    <v-img
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${moveItem.portOfLoadValue
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                  {{ moveItem.portOfLoadValue }}
                </v-chip>
                <v-chip
                  pill
                  small
                  v-if="moveItem.finalDestinationValue"
                  class="mx-1"
                >
                  <v-avatar size="14" left>
                    <v-img
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${moveItem.finalDestinationValue
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                  {{ moveItem.finalDestinationValue }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip>{{ moveItem.numContainers }}</v-chip>
            </v-list-item-action>
          </v-list-item>
          <p style="font-size: 12px">
            Are you sure you would like to move
            <span v-if="moveContainerType == 'single'"> this container?</span>
            <span v-else> the selected containers?</span>
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="bookingMoveConfirm = false"
              >No</v-btn
            >
            <v-btn
              v-if="moveContainerType == 'single'"
              color="success"
              class="mx-2"
              text
              small
              @click="moveSingleContainer(moveItem)"
              :loading="loadingCustomerBooking"
              >Yes</v-btn
            >
            <v-btn
              v-else-if="moveContainerType == 'multiple'"
              color="success"
              class="mx-2"
              text
              small
              @click="moveMultipleContainer(moveItem)"
              :loading="loadingCustomerBooking"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="containerItemModal" width="1200">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title v-if="containerItem.type == 'CONTAINER'"
            >Manage Container Item</v-toolbar-title
          >
          <v-toolbar-title v-else>Manage Breakbulk Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            @click="saveContainerItem"
            :disabled="
              !containerItem.isCarrierHaulage &&
              (!containerItem.transportCoordinator ||
                !containerItem.transportCoordinator.name)
            "
            :loading="savingContainerItem"
          >
            <v-icon small>save</v-icon>
          </v-btn>
          <v-btn icon color="redPop" @click="containerItemModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container style="width: 100%">
            <v-row>
              <v-col cols="12" sm="4" class="my-0 py-0">
                <v-list-item
                  style="height: 30px"
                  class="mx-0 px-0"
                >
                  <v-list-item-action class="mx-0">
                    <v-icon small>tag</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 13px"
                      >Quantity
                      </v-list-item-title
                    >
                    <v-list-item-subtitle style="font-size: 11px" v-if="booking.serviceType != 'BREAKBULK'"
                      >Number of containers
                      </v-list-item-subtitle
                    >
                    <v-list-item-subtitle style="font-size: 11px" v-else
                      >Number of breakbulk decks
                      </v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y rounded>
                      <template v-slot:activator="{ on }">
                        <v-chip v-on="on" small outlined label>
                          <span style="text-transform: none">
                            {{ containerItem.quantity }}
                          </span>
                          <v-icon small right>expand_more</v-icon>
                        </v-chip>
                      </template>
                      <v-list dense subheader style="max-height: 300px; overflow-y: auto">
                        <v-list-item v-for="i in 20" :key="i" @click="containerItem.quantity=(i+1)" style="height: 30px"
                        >
                          <v-list-item-content class="text-center">
                            <v-list-item-title>{{i}}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
                
                <v-text-field
                  dense
                  outlined
                  v-model="containerItem.customerContainerRef"
                  hide-details
                  class="my-1 mx-0"
                  clearable
                >
                  <template v-slot:label>
                    <span style="font-size: 13px">Customer Ref </span>
                  </template>
                </v-text-field>
                <v-list-item
                  style="height: 30px"
                  class="mx-0 px-0"
                  v-if="booking.movementType == 'EXPORT'"
                >
                  <v-list-item-action class="mx-0">
                    <v-icon small>scale</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 13px"
                      >VGM Method</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y rounded>
                      <template v-slot:activator="{ on }">
                        <v-chip v-on="on" small outlined label>
                          <v-icon small left>verified</v-icon>
                          <span style="text-transform: none">
                            {{ containerItem.verificationMethod }}
                          </span>
                          <v-icon small right>expand_more</v-icon>
                        </v-chip>
                      </template>
                      <v-list dense subheader>
                        <v-list-item
                          @click="containerItem.verificationMethod = 'Method 1'"
                        >
                          <v-list-item-content>
                            <v-list-item-title>Method 1</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="containerItem.verificationMethod = 'Method 2'"
                        >
                          <v-list-item-content>
                            <v-list-item-title>Method 2</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
                <v-list
                  dense
                  v-if="containerItem.type == 'CONTAINER'"
                  class="my-0 py-0"
                >
                  <v-list-item style="height: 30px" class="mx-0 px-0">
                    <v-list-item-action class="mx-0">
                      <v-icon small>widgets</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px"
                        >Container Type</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu offset-y rounded>
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" small outlined label>
                            <span style="text-transform: none">
                              {{ containerItem.containerTypeCode }}
                            </span>
                            <v-icon small right>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list
                          dense
                          subheader
                          style="max-height: 500px; overflow-y: auto"
                        >
                          <v-list-item
                            v-for="(containerType, index) in containerTypes"
                            :key="index"
                            @click="
                              containerItem.containerTypeCode =
                                containerType.typeCode
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px">{{
                                containerType.shortDescription
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list v-if="displayReeferSettings" dense class="my-0 py-0">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>ac_unit</v-icon> Reefer
                      Settings</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item style="height: 30px" class="mx-0 px-0">
                      <v-list-item-action class="mx-0">
                        <v-icon small>air</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px"
                          >Vent Settings</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small outlined label>
                              <span style="text-transform: none">
                                {{ containerItem.vents }}
                              </span>
                              <v-icon small right>expand_more</v-icon>
                            </v-chip>
                          </template>
                          <v-list
                            dense
                            subheader
                            style="max-height: 500px; overflow-y: auto"
                          >
                            <v-list-item
                              v-for="(vent, index) in ventSettings"
                              :key="index"
                              @click="containerItem.vents = vent"
                            >
                              <v-list-item-content>
                                <v-list-item-title style="font-size: 12px">{{
                                  vent
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-text-field
                      dense
                      outlined
                      v-model="containerItem.setPointTemp"
                      hide-details
                      class="my-1 mx-0"
                      clearable
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Set Temp (°C) </span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      dense
                      outlined
                      v-model="containerItem.humidity"
                      hide-details
                      class="my-1 mx-0"
                      clearable
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Humidity (%) </span>
                      </template>
                    </v-text-field>
                  </v-list>
                  <v-textarea v-model="containerItem.comment" outlined rows="3">
                    <template v-slot:label>
                      <span style="font-size: 13px">Notes </span>
                    </template>
                  </v-textarea>
                </v-list>
              </v-col>
              <v-col cols="12" sm="4" class="my-0 py-0">
                <v-list dense subheader>
                  <v-list dense subheader style="height: 250px">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">list</v-icon>
                      Cargo Items
                      <v-btn
                        icon
                        color="primary"
                        @click="addContainerCargoDetails"
                      >
                        <v-icon>add_circle_outline</v-icon></v-btn
                      >
                    </v-subheader>
                    <v-divider></v-divider>

                    <v-list-item
                      v-if="
                        !containerItem.bookingCargoItems ||
                        (containerItem.bookingCargoItems &&
                          containerItem.bookingCargoItems.length == 0)
                      "
                    >
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No products listed</span>
                      </v-list-item-content></v-list-item
                    >
                    <v-list-item
                      v-for="(cargo, index) in containerItem.bookingCargoItems"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="cargo.product"
                          style="font-size: 12px"
                        >
                          {{ cargo.product.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                          {{ cargo.hsCode }}
                          <span v-if="cargo.pallets">
                            - {{ cargo.pallets }} PALLETS</span
                          >
                          <span v-if="cargo.quantity">
                            - {{ cargo.quantity }}
                            {{ cargo.quantityType }}</span
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="
                            cargo.grossWeight ||
                            cargo.nettWeight ||
                            cargo.volume
                          "
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                          NETT {{ cargo.nettWeight }} KG - GROSS
                          {{ cargo.grossWeight }} KG - {{ cargo.volume }} CBM
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editCargoItem(cargo)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            @click="deleteCargoItem(index)"
                            color="red"
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-subheader style="font-size: 14px"
                    ><v-icon small class="mr-2">sell</v-icon>Products & Services
                    <v-btn
                      icon
                      color="primary"
                      @click="addContainerProductService"
                    >
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="
                      !containerItem.productServiceSales ||
                      containerItem.productServiceSales.length == 0
                    "
                  >
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No products listed</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="product in containerItem.productServiceSales"
                    :key="product.id"
                  >
                    <v-list-item-action class="mr-1">
                      <v-avatar
                        class="mr-2"
                        size="30"
                        :color="!product.icon ? 'secondary' : ''"
                      >
                        <v-img
                          v-if="product.icon"
                          :src="product.icon"
                          contain
                        ></v-img>
                        <h3 v-else>{{ product.name.charAt(0) }}</h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 12px"
                        class="text-wrap"
                        >{{ product.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="product.type == 'PRODUCT'"
                      >
                        <v-icon color="grey" class="mr-1 mb-1" small
                          >category</v-icon
                        >
                        Product</v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="font-size: 11px" v-else>
                        <v-icon color="grey" class="mr-1 mb-1" small
                          >engineering</v-icon
                        >
                        Service</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <!-- <v-btn icon small>
                          <v-icon small>launch</v-icon>
                        </v-btn> -->
                        <v-btn
                          icon
                          small
                          color="red"
                          @click="selectContainerProduct(product)"
                        >
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" sm="4" class="my-0 py-0">
                <v-list dense subheader :key="milestoneKey">
                  <v-list-item
                    @click="(partyType = 'Buyer'), (partyModal = true)"
                  >
                    <v-list-item-action>
                      <v-avatar
                        :color="
                          containerItem.buyer && containerItem.buyer.logo
                            ? 'white'
                            : 'secondary'
                        "
                        size="32"
                      >
                        <v-img
                          v-if="containerItem.buyer && containerItem.buyer.logo"
                          :src="containerItem.buyer.logo"
                          contain
                        ></v-img>
                        <h3
                          v-else-if="containerItem.buyer"
                          style="color: white"
                        >
                          {{
                            containerItem.buyer.name
                              ? containerItem.buyer.name.charAt(0)
                              : ""
                          }}
                        </h3>
                        <v-icon v-else small>shopping_cart</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="containerItem.buyer"
                        style="font-size: 12px"
                      >
                        {{ containerItem.buyer.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Preliminary Buyer</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="(partyType = 'Consignee'), (partyModal = true)"
                  >
                    <v-list-item-action>
                      <v-avatar
                        :color="
                          containerItem.consignee &&
                          containerItem.consignee.logo
                            ? 'white'
                            : 'secondary'
                        "
                        size="32"
                      >
                        <v-img
                          v-if="
                            containerItem.consignee &&
                            containerItem.consignee.logo
                          "
                          :src="containerItem.consignee.logo"
                          contain
                        ></v-img>
                        <h3
                          v-else-if="containerItem.consignee"
                          style="color: white"
                        >
                          {{
                            containerItem.consignee.name
                              ? containerItem.consignee.name.charAt(0)
                              : ""
                          }}
                        </h3>
                        <v-icon v-else small>call_received</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content :key="haulierKey">
                      <v-list-item-title
                        v-if="containerItem.consignee"
                        style="font-size: 12px"
                      >
                        {{ containerItem.consignee.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Preliminary Consignee</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="setSelectableParties(), (haulierModal = true)"
                    :key="haulierKey"
                  >
                    <v-list-item-action>
                      <v-avatar
                        :color="
                          containerItem.transportCoordinator &&
                          containerItem.transportCoordinator.logo
                            ? 'white'
                            : 'secondary'
                        "
                        size="32"
                      >
                        <v-img
                          v-if="
                            containerItem.transportCoordinator &&
                            containerItem.transportCoordinator.logo
                          "
                          :src="containerItem.transportCoordinator.logo"
                          contain
                        ></v-img>
                        <v-icon v-else small>local_shipping</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content :key="haulierKey">
                      <v-list-item-title
                        style="font-size: 12px"
                        v-if="containerItem.transportCoordinator"
                      >
                        {{ containerItem.transportCoordinator.name }}
                      </v-list-item-title>
                      <v-list-item-title
                        v-else-if="containerItem.isCarrierHaulage"
                        style="font-size: 12px"
                        >Carrier Haulage</v-list-item-title
                      >
                      <v-list-item-title
                        v-else-if="containerItem.isRailage"
                        style="font-size: 12px"
                        >Rail</v-list-item-title
                      >
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="containerItem.preAdviseRequired"
                        ><v-chip color="blue" x-small
                          >Preadvise Required</v-chip
                        ></v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="font-size: 11px"
                        >Transport Coordinator</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 14px"
                    >Pre-Carriage Legs / Loading Points
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="(milestone, index) in availableMilestones.filter(
                      (x) => x.type == booking.movementType
                    )"
                    :key="index"
                  >
                    <v-list-item-action class="mr-0 pr-0">
                      <v-avatar
                        size="10"
                        v-if="containerItem[milestone.value]"
                        :color="
                          containerItem[milestone.value].isActual
                            ? 'success'
                            : 'secondary'
                        "
                      ></v-avatar>
                      <v-icon v-else color="grey" small>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-if="containerItem[milestone.value]">
                      <v-list-item-title style="font-size: 12px">{{
                        containerItem[milestone.value].description
                      }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">{{
                        formatDateTime(
                          containerItem[milestone.value].date,
                          containerItem[milestone.value].time
                        )
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-subtitle style="font-size: 12px">{{
                        milestone.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        v-if="!containerItem[milestone.value]"
                        icon
                        small
                        color="primary"
                        @click="
                          addMilestone(containerItem, milestone.value, false)
                        "
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                      <v-row
                        justify="center"
                        v-if="containerItem[milestone.value]"
                      >
                        <v-btn
                          small
                          icon
                          @click="
                            addMilestone(containerItem, milestone.value, false)
                          "
                        >
                          <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn
                          small
                          color="red"
                          icon
                          @click="removeMilestone(milestone.value)"
                        >
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="haulierModal"
      width="900px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px">
            Manage Haulier
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="haulierModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense>
                <v-subheader style="font-size: 14px"
                  >Transport Type</v-subheader
                >
                <v-divider></v-divider>
                <v-list-item
                  v-for="haulier in hauliers"
                  :key="haulier.index"
                  @click="setHaulier(haulier)"
                >
                  <v-list-item-action>
                    <v-avatar color="white" size="36">
                      <v-img
                        v-if="haulier.logo"
                        :src="haulier.logo"
                        contain
                      ></v-img>
                      <v-icon v-else color="black">{{ haulier.icon }}</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px">{{
                      haulier.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      haulier.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="success" v-if="containerItem[haulier.flag]"
                      >check_circle_outline</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="6">
              <v-list
                style="max-height: 50vh; overflow-y: auto"
                dense
                :key="preadviseKey"
              >
                <v-subheader style="font-size: 16px"
                  >Transport Coordinator</v-subheader
                >
                <v-divider></v-divider>
              </v-list>
              <v-select
                :items="selectableParties"
                :disabled="containerItem.isCarrierHaulage"
                :placeholder="
                  containerItem.isCarrierHaulage
                    ? 'Select Coordinator'
                    : 'Not Applicable'
                "
                item-text="name"
                item-value="id"
                rounded
                style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw"
                hide-details
                outlined
                return-object
                v-model="containerItem.transportCoordinator"
                @change="setTransporter"
                clearable
                dense
              >
                <template v-slot:selection="data">
                  <v-row
                    align="center"
                    v-if="
                      containerItem.isPrivateHaulage || containerItem.isRailage
                    "
                  >
                    <v-avatar size="42" class="mr-2" v-if="data.item">
                      <v-img
                        v-if="data.item.icon"
                        contain
                        :src="data.item.icon"
                      ></v-img>
                    </v-avatar>
                    <span v-if="data.item">
                      {{ data.item.name }}
                    </span>
                  </v-row>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img
                      contain
                      v-if="data.item.logo"
                      :src="data.item.logo"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-list-item
                dense
                v-if="booking.movementType == 'EXPORT'"
                class="mt-2"
              >
                <v-list-item-action>
                  <v-icon color="secondary">article</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Pre-Advise </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="preadviseKey">
                  <v-switch
                    color="success"
                    v-model="containerItem.preAdviseRequired"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addMilestoneModal" width="500px">
      <ManageContainerMilestone
        :item="milestoneItem"
        :key="milestoneKey"
        :bookingType="booking.movementType"
        @close="addMilestoneModal = false"
        @unsavedItem="setMilestonePoint"
      />
    </v-dialog>

    <v-dialog v-model="containerProductServiceModal" width="400px">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"
            >Add Products & Services</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn @click="containerProductServiceModal = false" icon color="redPop">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="mt-2"
            hide-details
            placeholder="Search"
            v-model="searchContainerProduct"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-list dense class="my-1 py-0">
            <v-list-item
              v-for="product in filterAvailableContainerProducts"
              :key="product.id"
              @click="selectContainerProduct(product)"
              :style="{
                'border-left': selectedContainerProduct(product.id)
                  ? '4px solid var(--v-success-base)'
                  : '4px solid transparent',
              }"
            >
              <v-list-item-action class="mr-1">
                <v-avatar
                  class="mr-2"
                  size="30"
                  :color="!product.icon ? 'secondary' : ''"
                >
                  <v-img
                    v-if="product.icon"
                    :src="product.icon"
                    contain
                  ></v-img>
                  <h3 v-else>{{ product.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px" class="text-wrap">{{
                  product.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 11px"
                  v-if="product.type == 'PRODUCT'"
                >
                  <v-icon color="grey" class="mr-1 mb-1" small>category</v-icon>
                  Product</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" v-else>
                  <v-icon color="grey" class="mr-1 mb-1" small
                    >engineering</v-icon
                  >
                  Service</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" class="text-wrap">
                  {{ product.description }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="product.selected" color="success"
                  >check_circle</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterAvailableContainerProducts.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No products found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="partyModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingLinkedOrganisations">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Manage {{ partyType }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="(partyModal = false), (searchOrganisation = null)"
            >
            <v-icon small>close</v-icon>
            </v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchOrganisation"
            clearable
            hide-details
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterCustomerOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 13px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="organisation in filterCustomerOrganisations"
              :key="organisation.id"
              style="height: 45px"
              @click="setParty(organisation.relatedOrganisation)"
            >
              <v-list-item-action>
                <v-avatar
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                  size="30"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary" size="30">
                  <h3 style="color: white">
                    {{
                      organisation.relatedOrganisation.name
                        ? organisation.relatedOrganisation.name.charAt(0)
                        : ""
                    }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                  style="font-size: 12px"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

     <!-- Edit Cargo -->
     <v-dialog
      v-model="cargoModal"
      persistent
      width="450px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Cargo Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :disabled="
              !cargoItem.product || !cargoItem.hsCode || !cargoItem.description
            "
            @click="saveCargoItem"
            ><v-icon small>save</v-icon></v-btn
          >
          <v-btn icon color="redPop" @click="(cargoItem = {}), (cargoModal = false)">
            <v-icon small>close</v-icon>  
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select
                hide-details
                class="my-2"
                label="Type"
                :items="['Product Group', 'Product', 'Variety']"
                v-model="filterProductType"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-autocomplete
                class="my-2"
                hide-details
                ref="countrySelect"
                autofocus
                hide-no-data
                hide-selected
                label="Product"
                dense
                outlined
                :items="filterProducts"
                clearable
                item-text="name"
                item-value="id"
                :menu-props="{ closeOnContentClick: true }"
                @change="setCargoItemDetails"
                v-model="cargoItem.product"
                return-object
                :loading="loadingProduct"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="data.item.parentProduct"
                      style="font-size: 12px"
                    >
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-textarea
                hide-details
                class="my-2"
                rows="3"
                label="Description"
                v-model="cargoItem.description"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="HS Code"
                v-model="cargoItem.hsCode"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="No Pallets"
                type="number"
                v-model="cargoItem.pallets"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Nett Weight"
                suffix="Kg"
                v-model="cargoItem.nettWeight"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Gross Weight"
                suffix="Kg"
                v-model="cargoItem.grossWeight"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Cubic measure"
                suffix="CBM"
                v-model="cargoItem.volume"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Batch Number"
                v-model="cargoItem.batchNumber"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select
                hide-details
                class="my-2"
                label="Quantity Type"
                clearable
                v-model="selectedQuantityType"
                outlined
                dense
                :items="productQuantityTypes"
                @change="selectQuantityType()"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                :label="`Quantity`"
                v-model="cargoItem.quantity"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch
                label="Industrial Use"
                dense
                hide-details
                v-model="cargoItem.industrial"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch
                label="Organic"
                dense
                hide-details
                v-model="cargoItem.organic"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch
                label="Hazardous"
                dense
                v-model="cargoItem.hazardous"
              ></v-switch>
            </v-col>
          </v-row>
          <v-list dense subheader v-if="cargoItem.hazardous">
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px">Hazardous Details</v-subheader>
            <v-divider></v-divider>
            <v-row class="mt-2">
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
                v-if="cargoItem.hazardous"
              >
                <v-text-field
                  hide-details
                  class="my-2"
                  label="UN Code"
                  v-model="cargoItem.unCode"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
                v-if="cargoItem.hazardous"
              >
                <v-text-field
                  hide-details
                  class="my-2"
                  label="UN Packing Group"
                  v-model="cargoItem.unCodePackingGroup"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
                v-if="cargoItem.hazardous"
              >
                <v-text-field
                  hide-details
                  class="my-2"
                  :label="`Hazard Class`"
                  v-model="cargoItem.class"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Boolean from "../GridComponents/Boolean.vue";
import ContainerStatus from "../GridComponents/ContainerStatus.vue";
import dateFormat from "dateformat";
import FileStatus from "../BookingTables/ContainerComponents/FileStatus.vue";
import ManageContainerMilestone from "./ContentComponents/ManageContainerMilestone.vue";
import MovementType from "../BookingTables/BookingComponents/MovementType.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import POIField from "./ContentComponents/POIField.vue";
import ProductField from "./ContentComponents/ProductField.vue";
import ContainerStockStatus from "../GridComponents/ContainerStockStatus.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import TransportStatus from "../GridComponents/TransportStatus.vue";
import ViewContainer from "./ContentComponents/ViewContainer.vue";
export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    containerTypes: {
      type: Array,
      required: true,
    },
    productServices: {
      type: Array,
      required: true,
    }
  },
  components: {
    AgGridVue,
    Boolean,
    ContainerStatus,
    ContainerStockStatus,
    FileStatus,
    ManageContainerMilestone,
    MovementType,
    OrganisationName,
    POIField,
    ProductField,
    agColumnHeader: TableHeader,
    TransportStatus,
    ViewContainer,
  },
  data: () => ({
    addMilestoneModal: false,
    availableBookings: [],
    availableFiles: [],
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" },

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
    ],
    bookingCreateConfirm: false,
    bookingMoveError: false,
    bookingMoveConfirm: false,
    bookingQuery: null,
    bookingSplitTypeModal: false,
    bookingTimeout: null,
    bulkEditItem: {},
    bulkEditModal: false,
    cargoKey: 400,
    cargoItem: {},
    cargoModal: false,
    columns: [],
    containerItem: {},
    containerItemModal: false,
    containerRemovalType: null,
    containerProductServiceModal: false,
    confirmContainerCancel: false,
    container: null,
    creatingNewBooking: false,
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        flex: 1,
        minWidth: 150,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },
    fileItem: {},
    fileModal: false,
    fileTimeout: null,
    fileQuery: null,
    filterActive: false,
    filterProductType: "Product",
    gridApi: null,
    haulierModal: false,
    haulierKey: 200,
    initialState: {},
    loadingCancel: false,
    loadingCustomerBooking: false,
    loadingFiles: false,
    linkedOrganisations: [],
    loadingLinkedOrganisations: false,
    loadingProduct: false,
    milestoneItem: {},
    milestoneModal: false,
    milestoneKey: 100,
    moveContainerType: null,
    moveContainerModal: false,
    moveItem: null,
    partyModal: false,
    partyType: null,
    preadviseKey: 300,
    products: {
      data: []
    },
    selectedQuantityType: { value: "", text: "" },
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    savingContainerItem: false,
    search: null,
    searchBooking: null,
    searchContainerProduct: null,
    searchFile: null,
    searchOrganisation: null,
    searchTimeout: null,
    selectableParties: [],
    selectedRows: [],
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
    ventSettings: [],
    viewContainerModal: false,
  }),
  watch: {
    "booking.customerId": {
      immediate: true,
      async handler(val) {
        if (val) {
          this.loadingLinkedOrganisations = true;
          this.linkedOrganisations = await this.$API.getCustomerRelationships(
            val
          );
          this.loadingLinkedOrganisations = false;
        }
      },
    },
    "booking.regimeId":{
      immediate: true,
      handler(val) {
        if (val) {
          this.getRegimeCodeSettings();
        }
      }
    },
    "booking.movementType": {
      immediate: true,
      handler(val) {
        if (val === "IMPORT") {
          this.columns = this.importColumns();
        } else {
          this.columns = this.exportColumns();
        }
      },
    },
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  computed: {
    displayReeferSettings() {
      let result = false;
      if (this.containerItem && this.containerItem.containerTypeCode) {
        let findType = this.containerTypes.find(
          (x) => x.typeCode == this.containerItem.containerTypeCode
        );
        result = findType && findType.typeCategory == "Reefer";
      }
      return result;
    },
    filterAvailableContainerProducts() {
      let result = this.productServices.filter((x) => x.level == "CONTAINER");
      if (this.searchContainerProduct) {
        result = result.filter((x) =>
          x.name
            .toLowerCase()
            .includes(this.searchContainerProduct.toLowerCase())
        );
      }
      return result;
    },
    hauliers() {
      let result = [
        {
          index: 2,
          type: "Carrier Haulage",
          name: "Carrier Haulage",
          logo: null,
          icon: "directions_boat",
          flag: "isCarrierHaulage",
        },
        {
          index: 4,
          type: "Merchant Haulage",
          name: "Merchant Haulage",
          icon: "local_shipping",
          logo: null,
          flag: "isPrivateHaulage",
        },
        {
          index: 3,
          type: "Railage",
          name: "Rail",
          icon: "train",
          logo: null,
          flag: "isRailage",
        },
      ];
      return result;
    },
    filterAvailableBookings() {
      let result = this.availableBookings.filter(
        (x) => x.id != this.booking.id
      );
      if (this.searchBooking) {
        result = result.filter(
          (x) =>
            x.orderNo
              .toLowerCase()
              .includes(this.searchBooking.toLowerCase()) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase())) ||
            (x.mainCarriageVesselName &&
              x.mainCarriageVesselName
                .toLowerCase()
                .includes(this.searchBooking.toLowerCase()))
        );
      }
      return result;
    },
    filterAvailableFiles() {
      let result = this.availableFiles;
      if (this.fileItem && this.fileItem.shipmentFileId) {
        result = result.filter((x) => x.id != this.fileItem.shipmentFileId);
      }
      if (this.searchFile) {
        result = result.filter((x) =>
          x.fileNumber.toLowerCase().includes(this.searchFile.toLowerCase())
        );
      }
      return result;
    },
    filterCustomerOrganisations() {
      let result = this.linkedOrganisations;
      if (this.booking.customer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.booking.customerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.booking.customer,
            relatedOrganisationId: this.booking.customerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result = result.sort((a, b) =>
        a.relatedOrganisation.name.localeCompare(b.relatedOrganisation.name)
      );
      return result;
    },
    filterProducts() {
      let result = this.products.data.filter((x) => x.hsCode);
      if (result && this.filterProductType) {
        result = result.filter((x) => x.type == this.filterProductType);
      }
      return result;
    },
  },
  async created() {
    if (
      localStorage[
        "bookingContentTableState_" + this.$store.state.currentOrg.id
      ]
    ) {
      let state = JSON.parse(
        localStorage[
          "bookingContentTableState_" + this.$store.state.currentOrg.id
        ]
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.loadProducts();
  },
  methods: {
    addContainerProductService() {
      if (!this.containerItem.productServiceSales) {
        this.containerItem.productServiceSales = [];
      }
      // this.productServiceItem = {
      //   index: this.booking.productServiceSales.length,
      //   level: "SHIPMENT",
      // };
      this.containerProductServiceModal = true;
    },
    async addContainerDetails(type = null) {
      if (this.booking.serviceType == "BREAKBULK") {
        this.containerItem = {
          quantity: 1,
          bookingCargoItems: [],
          productServiceSales: [],
          type: "BREAKBULK",
          index: this.booking.bookingContainerItems.length,
          consignee: this.booking.consigneeId
            ? this.linkedOrganisations.find(
                (x) => x.relatedOrganisationId == this.booking.consigneeId
              )?.relatedOrganisation
            : null,
          consigneeId: this.booking.consigneeId,
          buyer: this.booking.buyerId
            ? this.linkedOrganisations.find(
                (x) => x.relatedOrganisationId == this.booking.buyerId
              )?.relatedOrganisation
            : null,
          buyerId: this.booking.buyerId,
          verificationMethod:
            this.booking.movementType == "EXPORT" ? "Method 2" : null,
        };
        if (!this.containerItem.buyer) {
          this.containerItem.buyerId = null;
        }
        if (!this.containerItem.consignee) {
          this.containerItem.consigneeId = null;
        }
        this.booking.bookingContainerItems.push(this.containerItem);
        // this.gridApi.applyTransaction({ add: [containerItem] });
      } else {
        if (!this.booking.bookingContainerItems) {
          this.booking.bookingContainerItems = [];
        }
        this.containerItem = {
          type: "CONTAINER",
          quantity: 1,
          bookingCargoItems: [],
          containerTypeCode: "45R1",
          containerType: "40 RF HC (45R1)",
          productServiceSales: [],
          index: this.booking.bookingContainerItems.length,
          consignee: this.booking.consigneeId
            ? this.linkedOrganisations.find(
                (x) => x.relatedOrganisationId == this.booking.consigneeId
              )?.relatedOrganisation
            : null,
          consigneeId: this.booking.consigneeId,
          buyer: this.booking.buyerId
            ? this.linkedOrganisations.find(
                (x) =>
                  x.relatedOrganisationId ==
                  this.booking.buyerId?.relatedOrganisation
              )
            : null,
          buyerId: this.booking.buyerId,
          verificationMethod:
            this.booking.movementType == "EXPORT" ? "Method 2" : null,
        };
        if (!this.containerItem.buyer) {
          this.containerItem.buyerId = null;
        }
        if (!this.containerItem.consignee) {
          this.containerItem.consigneeId = null;
        }
        if (this.booking.vents) {
          this.containerItem.vents = this.booking.vents;
        } else if (this.ventSettings.length == 1) {
          this.containerItem.vents = this.ventSettings[0];
        }
        if (this.booking.regime) {
          this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        }
        if (this.booking.caProduct) {
          this.containerItem.caProduct = this.booking.caProduct;
          this.containerItem.oxygen = this.booking.oxygen;
          this.containerItem.carbonDioxide = this.booking.carbonDioxide;
          this.containerItem.nitrogen = 0;
        }
        this.booking.bookingContainerItems.push(this.containerItem);
      }
      this.setHaulier(this.hauliers[0])
      this.containerItemModal = true;
    },
    selectQuantityType() {
      this.cargoItem.quantityTypeCode = this.selectedQuantityType.value;
      this.cargoItem.quantityType = this.selectedQuantityType.text;
    },
    addContainerCargoDetails() {
      this.cargoItem = {
        type: "container",
        index: this.containerItem.bookingCargoItems.length,
        quantityType: "Carton",
        quantityTypeCode: "CT",
      };
      let findContainer = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      if (findContainer) {
        this.cargoItem.volume = 25 * findContainer.teu;
      }

      this.cargoModal = true;
    },
    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: this.booking.portOfLoadValue,
          autosave,
          isActual: false,
        };
      }

      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    cellValueValidation(data){
      if(data.column && data.column.colId == 'containerNo' && this.booking.serviceType !='BREAKBULK'){
        console.log(data)
      }
    },
    async clickAction(container, action) {
      this.container = container;
      switch (action) {
        case "view_container":
          this.viewContainer(container);
          break;
        case "move_container":
          this.moveContainer("single");
          break;
        case "content_copy":
          await this.duplicateContainer(container);
          break;
        case "remove_container":
          this.containerRemovalType = "single";
          this.confirmContainerCancel = true;
          break;
        case "reinstate_container":
          this.reinstateContainer(container);
          break;
        //   case "mark_ghost":
        //     this.ghostItem = {
        //       status: "Ghost",
        //       ghostReason: this.ghostReasonList[0],
        //       ghostParty: null,
        //     };
        //     this.ghostType = "single";
        //     this.ghostModal = true;
        //     break;
        //   case "mark_claim":
        //     this.markClaimContainer(this.container);
        //     break;
        //   case "container_telemetry":
        //     this.trackContainerMetrics(this.container)
        //     break;
      }
    },
    async clickMassAction(action) {
      switch (action) {
        case "remove_containers":
          this.containerRemovalType = "multiple";
          this.confirmContainerCancel = true;
          break;
        case "move_container":
          this.moveContainer("multiple");
          break;
        case "link_to_file":
          this.editFileMultiple();
          break;
        case "split_booking":
          this.bookingSplitTypeModal = true;
          break;
      }
    },
    async createBookingMultipleContainer() {
      this.creatingNewBooking = true;
      let obj = {
        bookingId: this.booking.id,
        ghost: this.ghostSplitTypeModal ?? false,
        bookingContainers: this.selectedRows.map((x) => x.id),
      };
      let result = await this.$API.createSplitBooking(obj);
      this.bookingCreateConfirm = false;
      this.$message.success("Successfully split booking!");
      this.$router.push({
        path: "/booking/" + result.systemReference,
      });
      // this.chosenContainers = [];
      // this.chosenGhostContainers = [];
      // this.getContainers();
      this.creatingNewBooking = false;
    },
    async deleteContainer(item) {
      this.loadingCancel = true;
      item.status = "Cancelled";
      item.formattedStatus = "Cancelled";
      item.isDeleted = true;
      let result = await this.$API.cancelBookingContainers({
        containers: [item.id],
      });
      let containerIndex = this.booking.bookingContainers.findIndex(
        (x) => x.id == item.id
      );
      if (containerIndex > -1) {
        this.booking.bookingContainers[containerIndex] = item;
      }
      let rowNode = this.gridApi.getRowNode(item.id);
      if (rowNode) {
        rowNode.setData(item);
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      }
      this.loadingCancel = false;
      this.$message.success("Successfully cancelled container!");
      this.confirmContainerCancel = false;
    },
    async deleteMultipleContainers() {
      this.loadingCancel = true;

      let result = await this.$API.cancelBookingContainers({
        containers: this.selectedRows.map((x) => x.id),
      });

      if (result) {
        // this.getContainers();
        for (let i = 0; i < this.selectedRows.length; i++) {
          this.selectedRows[i].status = "Cancelled";
          this.selectedRows[i].formattedStatus = "Cancelled";
          this.selectedRows[i].isDeleted = true;
          let rowNode = this.gridApi.getRowNode(this.selectedRows[i].id);
          if (rowNode) {
            rowNode.setData(this.selectedRows[i]);
            this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
          }
        }
        this.loadingCancel = false;
        this.confirmContainerCancel = false;
      }
    },
    async duplicateContainer(original) {
      let bookingRef = this.booking.orderNo;
      let highest = this.booking.bookingContainers.reduce((max, container) => {
        let number = parseInt(container.ctoNo.split("/")[1]);
        return number > max ? number : max;
      }, 0);
      let high = highest + 1;
      let numRef = high < 10 ? `0${high}` : `${high}`;
      let newCTOnum = `${bookingRef}/${numRef}`;
      let duplicateContainer = {
        // ...original,
        // ctoNo: newCTOnum,
        bookingCargoItems: original.containerProducts,
        containerMilestones: original.containerMilestones.filter(
          (milestone) =>
            milestone.type !== "PLUG_IN" && milestone.type !== "PLUG_OUT"
        ),
        productServiceSales: original.productServiceSales,
        transportCoordinator: original.transportCoordinator,
        booking: original.booking,
        organisationId: original.organisationId,
        transportCoordinatorId: original.transportCoordinatorId,
        bookingId: original.bookingId,
        isActive: true,
        isDeleted: false,
        setPointTemp: original.setPointTemp,
        isConfirmedBooking: original.isConfirmedBooking,
        humidity: original.humidity,
        nitrogen: original.nitrogen,
        carbonDioxide: original.carbonDioxide,
        oxygen: original.oxygen,
        caProduct: original.caProduct,
        verificationMethod: original.verificationMethod,
        transportStatus: "Unallocated",
        nettWeight: original.nettWeight,
        grossWeight: original.grossWeight,
        packageType: original.packageType,
        containerTypeCode: original.containerTypeCode,
        gensetRequired: original.gensetRequired,
        type: original.type,
        isTempCheck: original.isTempCheck,
        tempRecorderType: original.tempRecorderType,
        // id: null,
        selected: false,
        duplicated: true,
        status: null,
        shipmentFile: null,
        shipmentFileId: null,
        statusLocation: null,
        quantity: 1,
      };
      duplicateContainer.productServiceSales.forEach((product) => {
        delete product.id;
        delete product.createdAt;
        delete product.updatedAt;
      });
      duplicateContainer.containerMilestones.forEach((milestone) => {
        milestone.id = null;
        milestone.date = null;
        milestone.time = null;
      });
      //remove containermilestone if milestone.type === 'PLUG_IN'
      duplicateContainer.bookingCargoItems.forEach((product) => {
        product.id = null;
      });
      let res = await this.$API.createContainerItem(
        this.booking.id,
        duplicateContainer
      );
      if (res) {
        this.$message({
          type: "success",
          message: `The container has been succesfully cloned!`,
        });
        duplicateContainer.ctoNo = newCTOnum;
        duplicateContainer.id = res[0].id;
        // this.booking.bookingContainers.push(duplicateContainer);
        this.$emit("refreshContainers");
      } else {
        this.$message({
          type: "error",
          message: `Something went wrong while duplicating the container. Please try again. \n If the problem persists, please contact support.`,
        });
      }
    },
    editFile(item) {
      if (
        !item.isDeleted &&
        item.status != "Ghost" &&
        item.bookingId == this.booking.id
      ) {
        if (
          // !this.booking.shipper
          // || !this.booking.consignee
          !this.booking.originCountry ||
          !this.booking.destinationCountry ||
          !this.booking.regimeCode ||
          !this.booking.portOfLoadValue ||
          !this.booking.portOfDischargeValue ||
          !this.booking.finalDestinationValue ||
          !this.booking.vessel ||
          !this.booking.contractNumber ||
          !this.booking.mainCarriageConveyanceNumber ||
          !this.booking.etd ||
          !this.booking.eta
          // || !this.booking.incoTerm
        ) {
          this.createFileError = true;
        } else {
          this.searchFile = null;
          this.fileItem = item;
          this.fileItem.type = "single";
          this.fileModal = true;
          this.getAvailableFiles();
        }
      }
    },
    editFileMultiple() {
      if (
        !this.booking.originCountry ||
        !this.booking.destinationCountry ||
        !this.booking.regimeCode ||
        !this.booking.portOfLoadValue ||
        !this.booking.portOfDischargeValue ||
        !this.booking.finalDestinationValue ||
        !this.booking.vessel ||
        !this.booking.contractNumber ||
        !this.booking.mainCarriageConveyanceNumber ||
        !this.booking.etd ||
        !this.booking.eta
        // || !this.booking.incoTerm
      ) {
        this.createFileError = true;
      } else {
        this.searchFile = null;
        this.fileItem = {
          type: "multiple",
        };
        this.fileModal = true;
        this.getAvailableFiles();
      }
    },
    deleteCargoItem(index) {
      this.containerItem.bookingCargoItems.splice(index, 1);
    },
    editCargoItem(item) {
      this.cargoItem = item;
      this.cargoModal = true;
    },
    exportColumns() {
      return [
        {
          field: "ctoNo",
          headerName: "CTO No.",
        },
        {
          headerName: this.booking.serviceType == 'BREAKBULK'?'Deck':"Container No.",
          field: "containerNo",
          editable: true,
        },
        {
          headerName: "Status",
          field: "formattedStatus",
          cellRenderer: "ContainerStatus",
          cellStyle: { textAlign: "center" },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: [
              "Allocated",
              "Ghost",
              "Moved",
              "Short Shipped",
              "Cancelled",
            ],
          },
        },
        {
          headerName: "Transport Status",
          field: "transportStatus",
          cellRenderer: "TransportStatus",
          cellStyle: { textAlign: "center" },
        },
        {
          headerName: "Stock Status",
          field: "stockStatus",
          cellRenderer: "ContainerStockStatus",
        },
        {
          field: "shipmentFile.fileNumber",
          headerName: "File",
          cellRenderer: "FileStatus",
          cellRendererParams: (p) => {
            return p.data && p.data.shipmentFile
              ? {
                  status: p.data.shipmentFile.status,
                  fileNumber: p.data.shipmentFile.fileNumber,
                }
              : p.value;
          },
        },
        {
          field: "customerContainerRef",
          headerName: "Customer Ref.",
          editable: true,
        },

        {
          headerName: "Seal No.",
          field: "sealNo",
          editable: true,
        },
        {
          headerName: "Type",
          field: "containerTypeCode",
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: this.containerTypes.map((x) => x.typeCode),
            valueListMaxHeight: 400,
            valueListMaxWidth: 150,
          },
        },
        {
          headerName: "Transport Coordinator",
          field: "transportCoordinatorName",
          cellRenderer: "OrganisationName",
          cellRendererParams: (p) => {
            return p.data && p.data.transportCoordinator
              ? {
                  organisation: p.data.transportCoordinator,
                }
              : null;
          },
        },
        {
          headerName: "Method",
          field: "verificationMethod",
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: { values: ["Method 1", "Method 2"] },
        },
        {
          headerName: "Products",
          field: "products",
          cellRenderer: "ProductField",
          cellRendererParams: {
            booking: () => {
              return this.booking;
            },
          },
          valueGetter: (p) => {
            return p && p.data && p.data.containerProducts
              ? p.data.containerProducts
              : null;
          },
          valueFormatter: (p) => {
            return p && p.data && p.data.containerProducts
              ? p.data.containerProducts.map((x) => x.product.name)
              : null;
          },
        },
        {
          headerName: "Empty Pickup",
          children: [
            {
              headerName: "Depot",
              field: "milestones.EMPTY_PICKUP.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "EMPTY_PICKUP",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.EMPTY_PICKUP
                  ? p.data.milestones.EMPTY_PICKUP.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.EMPTY_PICKUP.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.EMPTY_PICKUP &&
                  p.data.milestones.EMPTY_PICKUP.date
                  ? this.$Format.formatDate(p.data.milestones.EMPTY_PICKUP.date)
                      .isoFormat +
                      " " +
                      (p.data.milestones.EMPTY_PICKUP.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.EMPTY_PICKUP &&
                  p.data.milestones.EMPTY_PICKUP.date
                  ? this.$Format.formatDate(p.data.milestones.EMPTY_PICKUP.date)
                      .isoFormat +
                      " " +
                      (p.data.milestones.EMPTY_PICKUP.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Loading Point 1",
          children: [
            {
              headerName: "Location",
              field: "milestones.LOADING_POINT_1.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "LOADING_POINT_1",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.LOADING_POINT_1
                  ? p.data.milestones.LOADING_POINT_1.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.LOADING_POINT_1.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.LOADING_POINT_1 &&
                  p.data.milestones.LOADING_POINT_1.date
                  ? this.$Format.formatDate(
                      p.data.milestones.LOADING_POINT_1.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.LOADING_POINT_1.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.LOADING_POINT_1 &&
                  p.data.milestones.LOADING_POINT_1.date
                  ? this.$Format.formatDate(
                      p.data.milestones.LOADING_POINT_1.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.LOADING_POINT_1.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Loading Point 2",
          children: [
            {
              headerName: "Location",
              field: "milestones.LOADING_POINT_2.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "LOADING_POINT_2",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.LOADING_POINT_2
                  ? p.data.milestones.LOADING_POINT_2.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.LOADING_POINT_2.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.LOADING_POINT_2 &&
                  p.data.milestones.LOADING_POINT_2.date
                  ? this.$Format.formatDate(
                      p.data.milestones.LOADING_POINT_2.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.LOADING_POINT_2.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.LOADING_POINT_2 &&
                  p.data.milestones.LOADING_POINT_2.date
                  ? this.$Format.formatDate(
                      p.data.milestones.LOADING_POINT_2.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.LOADING_POINT_2.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Port Holding",
          children: [
            {
              headerName: "Location",
              field: "milestones.FULL_DROP_OFF.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "FULL_DROP_OFF",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.FULL_DROP_OFF
                  ? p.data.milestones.FULL_DROP_OFF.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.FULL_DROP_OFF.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.FULL_DROP_OFF &&
                  p.data.milestones.FULL_DROP_OFF.date
                  ? this.$Format.formatDate(
                      p.data.milestones.FULL_DROP_OFF.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.FULL_DROP_OFF.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.FULL_DROP_OFF &&
                  p.data.milestones.FULL_DROP_OFF.date
                  ? this.$Format.formatDate(
                      p.data.milestones.FULL_DROP_OFF.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.FULL_DROP_OFF.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Weighbridge",
          children: [
            {
              headerName: "Location",
              field: "milestones.WEIGH_BRIDGE.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "WEIGH_BRIDGE",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.WEIGH_BRIDGE
                  ? p.data.milestones.WEIGH_BRIDGE.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.WEIGH_BRIDGE.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.WEIGH_BRIDGE &&
                  p.data.milestones.WEIGH_BRIDGE.date
                  ? this.$Format.formatDate(p.data.milestones.WEIGH_BRIDGE.date)
                      .isoFormat +
                      " " +
                      (p.data.milestones.WEIGH_BRIDGE.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.WEIGH_BRIDGE &&
                  p.data.milestones.WEIGH_BRIDGE.date
                  ? this.$Format.formatDate(p.data.milestones.WEIGH_BRIDGE.date)
                      .isoFormat +
                      " " +
                      (p.data.milestones.WEIGH_BRIDGE.time ?? "")
                  : null;
              },
            },
          ],
        },
      ];
    },
    importColumns() {
      return [
        {
          field: "ctoNo",
          headerName: "CTO No.",
        },
        {
          headerName: this.booking.serviceType == 'BREAKBULK'?'Deck':"Container No.",
          field: "containerNo",
          editable: true,
        },
        {
          headerName: "Status",
          field: "formattedStatus",
          cellRenderer: "ContainerStatus",
          cellStyle: { textAlign: "center" },
        },
        {
          headerName: "Transport Status",
          field: "transportStatus",
          cellRenderer: "TransportStatus",
          cellStyle: { textAlign: "center" },
        },
        {
          headerName: "Stock Status",
          field: "stockStatus",
          cellRenderer: "ContainerStockStatus",
        },
        {
          field: "shipmentFile.fileNumber",
          headerName: "File",
          cellRenderer: "FileStatus",
          cellRendererParams: (p) => {
            return p.data && p.data.shipmentFile
              ? {
                  status: p.data.shipmentFile.status,
                  fileNumber: p.data.shipmentFile.fileNumber,
                }
              : p.value;
          },
        },
        {
          field: "customerContainerRef",
          headerName: "Customer Ref.",
        },

        {
          headerName: "Seal No.",
          field: "sealNo",
        },
        {
          headerName: "Type",
          field: "containerTypeCode",
        },
        {
          headerName: "Transport Coordinator",
          field: "transportCoordinatorName",
          cellRenderer: "OrganisationName",
          cellRendererParams: (p) => {
            return p.data && p.data.transportCoordinator
              ? {
                  organisation: p.data.transportCoordinator,
                }
              : null;
          },
        },
        {
          headerName: "Method",
          field: "verificationMethod",
        },
        {
          headerName: "Products",
          field: "products",
        },
        {
          headerName: "Full Pickup",
          children: [
            {
              headerName: "Location",
              field: "milestones.FULL_PICKUP.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "FULL_PICKUP",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.FULL_PICKUP
                  ? p.data.milestones.FULL_PICKUP.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.FULL_PICKUP.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.FULL_PICKUP &&
                  p.data.milestones.FULL_PICKUP.date
                  ? this.$Format.formatDate(p.data.milestones.FULL_PICKUP.date)
                      .isoFormat +
                      " " +
                      (p.data.milestones.FULL_PICKUP.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.FULL_PICKUP &&
                  p.data.milestones.FULL_PICKUP.date
                  ? this.$Format.formatDate(p.data.milestones.FULL_PICKUP.date)
                      .isoFormat +
                      " " +
                      (p.data.milestones.FULL_PICKUP.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Delivery Point 1",
          children: [
            {
              headerName: "Location",
              field: "milestones.DELIVERY_POINT_1.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "DELIVERY_POINT_1",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_1
                  ? p.data.milestones.DELIVERY_POINT_1.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.DELIVERY_POINT_1.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_1 &&
                  p.data.milestones.DELIVERY_POINT_1.date
                  ? this.$Format.formatDate(
                      p.data.milestones.DELIVERY_POINT_1.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.DELIVERY_POINT_1.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_1 &&
                  p.data.milestones.DELIVERY_POINT_1.date
                  ? this.$Format.formatDate(
                      p.data.milestones.DELIVERY_POINT_1.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.DELIVERY_POINT_1.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Delivery Point 2",
          children: [
            {
              headerName: "Location",
              field: "milestones.DELIVERY_POINT_2.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "DELIVERY_POINT_2",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_2
                  ? p.data.milestones.DELIVERY_POINT_2.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.DELIVERY_POINT_2.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_2 &&
                  p.data.milestones.DELIVERY_POINT_2.date
                  ? this.$Format.formatDate(
                      p.data.milestones.DELIVERY_POINT_2.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.DELIVERY_POINT_2.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_2 &&
                  p.data.milestones.DELIVERY_POINT_2.date
                  ? this.$Format.formatDate(
                      p.data.milestones.DELIVERY_POINT_2.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.DELIVERY_POINT_2.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Delivery Point 3",
          children: [
            {
              headerName: "Location",
              field: "milestones.DELIVERY_POINT_3.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "DELIVERY_POINT_3",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_3
                  ? p.data.milestones.DELIVERY_POINT_3.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.DELIVERY_POINT_3.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_3 &&
                  p.data.milestones.DELIVERY_POINT_3.date
                  ? this.$Format.formatDate(
                      p.data.milestones.DELIVERY_POINT_3.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.DELIVERY_POINT_3.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.DELIVERY_POINT_3 &&
                  p.data.milestones.DELIVERY_POINT_3.date
                  ? this.$Format.formatDate(
                      p.data.milestones.DELIVERY_POINT_3.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.DELIVERY_POINT_3.time ?? "")
                  : null;
              },
            },
          ],
        },
        {
          headerName: "Empty Drop Off",
          children: [
            {
              headerName: "Location",
              field: "milestones.EMPTY_DROP_OFF.description",
              cellStyle: { justifyContent: "center" },
              cellRenderer: "POIField",
              cellRendererParams: {
                type: "EMPTY_DROP_OFF",
                booking: () => {
                  return this.booking;
                },
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.EMPTY_DROP_OFF
                  ? p.data.milestones.EMPTY_DROP_OFF.description
                  : null;
              },
            },
            {
              headerName: "Time Slot",
              field: "milestones.EMPTY_DROP_OFF.date",
              valueGetter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.EMPTY_DROP_OFF &&
                  p.data.milestones.EMPTY_DROP_OFF.date
                  ? this.$Format.formatDate(
                      p.data.milestones.EMPTY_DROP_OFF.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.EMPTY_DROP_OFF.time ?? "")
                  : null;
              },
              valueFormatter: (p) => {
                return p.data &&
                  p.data.milestones &&
                  p.data.milestones.EMPTY_DROP_OFF &&
                  p.data.milestones.EMPTY_DROP_OFF.date
                  ? this.$Format.formatDate(
                      p.data.milestones.EMPTY_DROP_OFF.date
                    ).isoFormat +
                      " " +
                      (p.data.milestones.EMPTY_DROP_OFF.time ?? "")
                  : null;
              },
            },
          ],
        },
      ];
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += " " + time;
        }
      }
      return result;
    },
    async getRegimeCodeSettings() {
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId);
        if (ventSettings.length > 0) {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) => ventSettings.map((y) => y.ventCode).includes(x));
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
      } else {
        this.ventSettings = [
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Closed",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
      }
      if (!this.booking.vents) {
        this.booking.vents = this.ventSettings[0];
        this.$emit('bookingChange')
      }
    },
    async getAvailableFiles() {
      if (this.loadingFiles) {
        clearTimeout(this.fileTimeout);
      }
      if (this.loadingFiles && this.fileQuery) {
        this.fileQuery.abort();
      }
      this.loadingFiles = true;
      let params = {
        search: this.searchFile,
        portOfLoadValue: this.booking.portOfLoadValue,
        finalDestinationValue: this.booking.finalDestinationValue,
        vesselId: this.booking.vesselId,
        voyage: this.booking.mainCarriageConveyanceNumber,
        shipperId: this.booking.shipperId,
        consigneeId: this.booking.consigneeId,
        contractOwner: this.booking.contractOwner,
        customerId: this.booking.customerId,
      };
      this.fileTimeout = setTimeout(async () => {
        this.fileQuery = new AbortController();
        const signal = this.fileQuery.signal;
        let data = await this.$API.listShipmentFileBooking({
          params: params,
          signal,
        });
        this.availableFiles = data.data;
        this.loadingFiles = false;
      }, 750);
    },
    getContextMenuItems(params) {
      let result = [];
      let items = [
        {
          name: "View Item",
          icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
          action: () => {
            if (params.node && params.node.data && params.node.data.id) {
              this.clickAction(params.node.data, "view_container");
            } else {
              this.$message.error(
                "Please select a single row to view the file"
              );
            }
          },
        },
        {
          name: "Move",
          icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">exit_to_app</span>',
          action: () => {
            if (params.node && params.node.data && params.node.data.id) {
              this.clickAction(params.node.data, "move_container");
            } else {
              this.$message.error(
                "Please select a single row to view the file"
              );
            }
          },
        },
        {
          name: "Clone Item",
          icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">content_copy</span>',
          action: () => {
            if (params.node && params.node.data && params.node.data.id) {
              this.clickAction(params.node.data, "content_copy");
            } else {
              this.$message.error(
                "Please select a single row to view the file"
              );
            }
          },
        },
        // {
        //   name: "Mark as Ghost",
        //   icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">category</span>',
        //   action: () => {
        //     if (params.node && params.node.data && params.node.data.id) {
        //       this.clickAction(params.node.data, "mark_ghost");
        //     } else {
        //       this.$message.error(
        //         "Please select a single row to view the file"
        //       );
        //     }
        //   },
        // },
        {
          name: "Remove",
          icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">cancel</span>',
          action: () => {
            if (params.node && params.node.data && params.node.data.id) {
              this.clickAction(params.node.data, "remove_container");
            } else {
              this.$message.error(
                "Please select a single row to view the file"
              );
            }
          },
        },
        {
          name: "Reinstate",
          icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">autorenew</span>',
          action: () => {
            if (params.node && params.node.data && params.node.data.id) {
              this.clickAction(params.node.data, "reinstate_container");
            } else {
              this.$message.error(
                "Please select a single row to view the file"
              );
            }
          },
        },
      ];

      // if (params.node.data && params.node.data.containerNo && this.canViewContainerTelemetry) {
      //   items.push({
      //     name: "Container Telemetry",
      //     color: "purple",
      //     icon: "timeline",
      //     action: "container_telemetry",
      //   })
      // }
      if (
        params.node.data &&
        params.node.data.status != "Cancelled" &&
        params.node.data.status != "Ghost"
      ) {
        items = items.filter((x) => x.name != "Reinstate");
      } else {
        items = items.filter((x) => x.name != "Mark as Ghost");
      }
      if (!params.node.group) {
        result = [
          ...items,
          "separator",
          {
            name: "Bulk Actions",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">format_list_numbered</span>',
            subMenu: [
              // {
              //   name: "Manage Products",
              //   action: () => {
              //     if (params.node && params.node.data && params.node.data.id) {
              //       this.clickMassAction("manage_products");
              //     } else {
              //       this.$message.error(
              //         "Please select a single row to view the file"
              //       );
              //     }
              //   },
              // },
              // {
              //   name: "Manage Milestones",
              //   action: () => {
              //     if (params.node && params.node.data && params.node.data.id) {
              //       this.clickMassAction("manage_milestones");
              //     } else {
              //       this.$message.error(
              //         "Please select a single row to view the file"
              //       );
              //     }
              //   },
              // },
              {
                name: "Move Selection",
                action: () => {
                  if (params.node && params.node.data && params.node.data.id) {
                    this.clickMassAction(params.node.data, "move_container");
                  } else {
                    this.$message.error(
                      "Please select a single row to view the file"
                    );
                  }
                },
              },

              {
                name: "Link to File",
                action: () => {
                  if (params.node && params.node.data && params.node.data.id) {
                    this.clickMassAction("link_to_file");
                  } else {
                    this.$message.error(
                      "Please select a single row to view the file"
                    );
                  }
                },
              },
              {
                name: "Remove Selection",
                action: () => {
                  if (params.node && params.node.data && params.node.data.id) {
                    this.clickMassAction("remove_containers");
                  } else {
                    this.$message.error(
                      "Please select a single row to view the file"
                    );
                  }
                },
              },
            ],
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    async getCustomerBooking() {
      if (this.loadingCustomerBooking) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loadingCustomerBooking && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loadingCustomerBooking = true;
      let params = {
        filter: { customerId: this.booking.customerId },
      };
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        let data = await this.$API.getBookings({
          params: params,
          signal,
        });
        this.availableBookings = data.data;
        this.loadingCustomerBooking = false;
      }, 750);
    },
    getRowId(params) {
      return params.data.id;
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
    },
    async load() {},
    moveContainer(type) {
      if (!this.booking.customer) {
        this.bookingMoveError = true;
      } else {
        this.moveContainerType = type;
        this.moveContainerModal = true;
        this.getCustomerBooking();
      }
    },
    async moveSingleContainer(item) {
      console.log(item)
      this.loadingCustomerBooking = true;
      let obj = {
        ...this.container,
        bookingId: item.id,
      };
      this.container.booking = item;
      this.container.bookingId = item.id;
      await this.$API.updateContainer(obj);
      this.$message.success("Container Moved Successfully");
      this.moveContainerModal = false;
      this.bookingMoveConfirm = false;

      this.loadingCustomerBooking = false;
    },
    async moveMultipleContainer(item) {
      this.loadingCustomerBooking = true;
      if (!this.ghostSplitTypeModal) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          let container = this.selectedRows[i];
          let obj = {
            ...container,
            bookingId: item.id,
          };
          container.booking = item;
          container.bookingId = item.id;
          await this.$API.updateContainer(obj);
        }
      } else {
        let result = await this.$API.assignGhostContainers({
          bookingId: item.id,
          bookingContainers: this.chosenGhostContainers.map((x) => x.id),
        });
      }
      this.$emit("refreshContainers");
      this.$message.success("Container Moved Successfully");
      this.moveContainerModal = false;
      this.moveItem = null;
      this.bookingMoveConfirm = false;
      this.loadingCustomerBooking = false;
    },
    async moveContainerConfirm(item) {
      this.moveContainerModal = false;
      // this.loadingCustomerBooking = true
      this.moveItem = item;
      this.bookingMoveConfirm = true;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.gridApi.addEventListener(
        "selectionChanged",
        this.rowSelectionChanged
      );
      this.gridApi.addEventListener('cellValueChanged', this.cellValueValidation)
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "bookingContentTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    async reinstateContainer(container) {
      if (container.status === "Cancelled") {
        let item = this.booking.bookingContainers.find(
          (x) => x.id == container.id
        );
        item.isDeleted = false;
        item.status = "Unallocated";
        item.formattedStatus = "Unallocated";
      } else if (container.status == "Ghost") {
        let item = this.booking.bookingContainers.find(
          (x) => x.id == container.id
        );
        item.status = null;
        item.ghostReason = null;
        item.ghostParty = null;
        item.ghostNote = null;
      }
      let status = container.transportCoordinator
        ? container.status
        : "Unallocated";
      await this.$API.reinstateBookingContainer({
        container: container.id,
        status: status,
      });
    },
    removeMilestone(milestone) {
      this.containerItem.containerMilestones =
        this.containerItem.containerMilestones.filter(
          (x) => x.type != milestone
        );
      let filteredMilestones = this.availableMilestones.filter(
        (x) => x.type == this.booking.movementType
      );
      for (let data of filteredMilestones) {
        let find = this.containerItem.containerMilestones.find(
          (x) => x.type == data.value
        );
        this.containerItem[data.value] = find ?? null;
      }
      this.milestoneKey++;
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    rowSelectionChanged(event) {
      if (event.source != "gridInitializing") {
        let rows = this.gridApi.getSelectedRows();
        this.selectedRows = rows;
      } else {
        this.gridApi.deselectAll();
        this.selectedRows = [];
      }
    },
    saveCargoItem() {
      if (this.booking.regime) {
        this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        if (
          this.booking.regime.regimeVents &&
          this.booking.regime.regimeVents.length > 0
        ) {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) =>
            this.booking.regime.regimeVents.map((x) => x.ventCode).includes(x)
          );
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
        if (this.ventSettings.length == 1) {
          this.containerItem.vents = this.ventSettings[0];
        }
      }
      if (this.cargoItem.type == "container") {
        let find = this.containerItem.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;

        if (find > -1) {
          this.containerItem.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.containerItem.bookingCargoItems.push(this.cargoItem);
        }
      } else {
        let find = this.booking.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;
        if (find > -1) {
          this.booking.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.booking.bookingCargoItems.push(this.cargoItem);
        }
      }
      this.cargoKey++;
      this.cargoModal = false;
      this.cargoItem = {};
    },
    async saveContainerItem() {
      try {
        this.savingContainerItem = true;
        if (this.containerItem.verificationMethod == "Method 2") {
          this.containerItem.weighBridgeId = null;
          this.containerItem.containerType = null;
          this.containerItem.verificationMethodOwner = null;
        }
        if (this.booking.regime) {
          this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        }
        let findType = this.containerTypes.find(
          (x) => x.typeCode == this.containerItem.containerTypeCode
        );
        this.containerItem.containerTypeDescription = findType
          ? findType.shortDescription
          : null;
        let res = await this.$API.createContainerItem(
          this.booking.id,
          this.containerItem
        );
        this.$message.success("Successfully added containers!");
        this.containerItemModal = false;
        if (this.booking.integrationReference) {
          this.booking.integrationChange = true;
          await this.$API.updateBooking(this.booking);
        }
        this.$emit('refreshContainers');
        this.savingContainerItem = false;
      } catch (e) {
        this.$message.error("An error occurred :(");
        this.savingContainerItem = false;
      }
    },
    selectedContainerProduct(id) {
      let findProduct =
        this.containerItem &&
        this.containerItem.productServiceSales &&
        this.containerItem.productServiceSales.find((x) => x.id == id);
      return findProduct ? true : false;
    },
    selectContainerProduct(product) {
      if (this.selectedContainerProduct(product.id)) {
        let findProduct = this.containerItem.productServiceSales.findIndex(
          (x) => x.id == product.id
        );
        this.containerItem.productServiceSales.splice(findProduct, 1);
      } else {
        this.containerItem.productServiceSales.push(product);
      }
      this.selectedProductKey++;
    },    async setContainerFile(file) {
      this.loadingFiles = true;
      this.fileItem.status = "Allocated";
      this.fileItem.shipmentFile = file;
      this.fileItem.shipmentFileId = file.id;
      await this.$API.updateContainer({
        id: this.fileItem.id,
        shipmentFileId: file.id,
        status: "Allocated",
      });
      this.$message.success("Successfully allocated container!");
      this.fileModal = false;
      this.loadingFiles = false;
      let rowNode = this.gridApi.getRowNode(this.fileItem.id);
      if (rowNode) {
        rowNode.setData(this.fileItem);
      }
      this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
    },
    async setContainerMultipleFile(file) {
      this.loadingFiles = true;
      let refreshNodes = [];
      for (let i = 0; i < this.selectedRows.length; i++) {
        this.selectedRows[i].status = "Allocated";
        this.selectedRows[i].shipmentFile = file;
        this.selectedRows[i].shipmentFileId = file.id;
        await this.$API.updateContainer({
          id: this.selectedRows[i].id,
          shipmentFileId: file.id,
          status: "Allocated",
        });
        let rowNode = this.gridApi.getRowNode(this.selectedRows[i].id);
        if (rowNode) {
          rowNode.setData(this.selectedRows[i]);
          refreshNodes.push(rowNode);
        }
      }
      this.gridApi.refreshCells({ rowNodes: refreshNodes, force: true });
      // this.fileItem.status = 'Allocated'
      // this.fileItem.shipmentFile = file
      // this.fileItem.shipmentFileId = file.id
      // await this.$API.updateContainer({
      //     id: this.fileItem.id,
      //     shipmentFileId: file.id,
      //     status: 'Allocated'
      // })
      this.$message.success("Successfully allocated container!");
      this.fileModal = false;
      this.selectedRows = [];
      this.loadingFiles = false;
    },
    setCargoItemDetails() {
      delete this.cargoItem.id;
      if (this.cargoItem.product) {
        this.cargoItem.description = this.cargoItem.product.hsCode.explanation;
        this.cargoItem.hsCode =
          this.cargoItem.product.hsCode.alternateCode.replace(/ /g, "");
        this.cargoItem.productId = this.cargoItem.product.id;
      }
    },
    setHaulier(haulier) {
      let types = ["isPrivateHaulage", "isCarrierHaulage", "isRailage"];
      if (haulier.flag) {
        let setFalse = types.filter((x) => x != haulier.flag);
        for (let i = 0; i < setFalse.length; i++) {
          this.containerItem[setFalse[i]] = false;
        }
        this.containerItem[haulier.flag] = true;
        this.containerItem.transportCoordinator = null;
        this.containerItem.transportCoordinatorId = null;
      } else {
        for (let i = 0; i < types.length; i++) {
          this.containerItem[types[i]] = false;
        }
        this.containerItem.transportCoordinator = haulier;
        this.containerItem.transportCoordinatorId = haulier.id;
        // this.booking.preAdvise = true
      }
      this.haulierKey++;
      // this.haulierModal = false
    },
    setTransporter() {
      if (this.containerItem.transportCoordinator) {
        this.containerItem.transportCoordinatorId =
          this.containerItem.transportCoordinator.id;
      } else {
        this.containerItem.transportCoordinatorId = null;
      }
      if (
        this.containerItem.transportCoordinatorId ==
          this.$store.state.currentOrg.id &&
        this.booking.movementType == "EXPORT"
      ) {
        this.containerItem.preAdviseRequired = true;
      }
      this.preadviseKey++;
    },
    setMilestonePoint(item) {
      if (item.type) {
        this.containerItem[`${item.type}`] = item;
      }
      if (!this.containerItem.containerMilestones) {
        this.containerItem.containerMilestones = [];
      }
      let find = this.containerItem.containerMilestones.findIndex(
        (x) => x.type == item.type
      );
      if (find == -1) {
        this.containerItem.containerMilestones.push(item);
      } else {
        this.containerItem.containerMilestones[find] = item;
      }
      this.addMilestoneModal = false;
    },
    setParty(organisation) {
      if (!this.containerItemModal) {
        switch (this.partyType) {
          case "Shipper":
            this.booking.shipper = organisation;
            this.booking.shipperId = organisation.id;
            break;
          case "Consignee":
            this.booking.consignee = organisation;
            this.booking.consigneeId = organisation.id;
            break;
          case "Forwarder":
            this.booking.forwarder = organisation;
            this.booking.forwarderId = organisation.id;
            break;
          case "Buyer":
            this.booking.buyer = organisation;
            this.booking.buyerId = organisation.id;
            break;
          case "First Notify":
            this.booking.firstNotify = organisation;
            this.booking.firstNotifyId = organisation.id;
            break;
          case "Second Notify":
            this.booking.secondNotify = organisation;
            this.booking.secondNotifyId = organisation.id;
            break;
          case "Courier Party":
            this.booking.courierParty = organisation;
            this.booking.courierPartyId = organisation.id;
            break;
          case "Stock Provider":
            this.booking.stockProvider = organisation;
            this.booking.stockProviderId = organisation.id;
            break;
        }
      } else {
        switch (this.partyType) {
          case "Consignee":
            this.containerItem.consignee = organisation;
            this.containerItem.consigneeId = organisation.id;
            break;
          case "Buyer":
            this.containerItem.buyer = organisation;
            this.containerItem.buyerId = organisation.id;
            break;
        }
      }
      this.partyModal = false;
    },
    setSelectableParties() {
      let keys = [];
      if (this.booking.movementType == "EXPORT") {
        keys = [
          "forwarder",
          "shipper",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      } else {
        keys = [
          "forwarder",
          "consignee",
          "shipper",
          "buyer",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }

      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.booking[keys[i] + "Id"]) {
          result.push(this.booking[keys[i]]);
        }
      }
      if (this.booking.customerId) {
        let findCustomer = result.find((x) => x.id == this.booking.customerId);
        if (!findCustomer) {
          result.unshift(this.booking.customer);
        }
      }
      let findCurrentOrg = result.find(
        (x) => x.id == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        result.unshift(this.$store.state.currentOrg);
      }
      this.selectableParties = result;
    },
    viewContainer(item) {
      this.container = item;
      this.viewContainerModal = true;
    },
  },
};
</script>