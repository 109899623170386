<template>
  <div>
    <v-chip
      small
      outlined
      style="border: none; font-size: 11px"
      v-if="item"
      @click="addMilestone()"
      >{{ item }}</v-chip
    >
    <v-btn v-else small color="grey" icon @click="addMilestone()">
      <v-icon small>add_circle_outline</v-icon>
    </v-btn>

    <v-dialog
      v-model="addMilestoneModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      v-if="booking"
    >
      <ManageContainerMilestone
        :item="milestoneItem"
        :bookingType="booking.movementType"
        :key="milestoneKey"
        @close="addMilestoneModal = false"
        @save="milestoneUpdate"
      />
    </v-dialog>
  </div>
</template>
<script>
import ManageContainerMilestone from "./ManageContainerMilestone.vue";
export default {
  components: {
    ManageContainerMilestone,
  },
  data: () => ({
    booking: null,
    data: null,
    item: null,
    milestoneItem: {},
    addMilestoneModal: false,
    milestoneKey: 0,
    milestoneContainer: {},
    poi: {},
  }),
  mounted() {
    this.item = this.params.value;
    this.data = this.params.data;
    this.booking = this.params.booking();
  },
  methods: {
    addMilestone(autosave = true) {
      let item = this.data;
      if (this.params.type && item[this.params.type]) {
        this.milestoneItem = item[this.params.type];
        this.milestoneItem.autosave = autosave;
        this.milestoneContainer = item;
      } else {
        this.milestoneItem = {
          type: this.params.type,
          bookingContainerId: this.params.data.id,
          pol: this.booking.portOfLoadValue,
          autosave,
          isActual: false,
        };
        this.milestoneContainer = item;
      }
      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    milestoneUpdate(data) {
      let node = this.params.api.getRowNode(this.params.data.id);
      let item = { ...this.params.data };
      item.containerMilestones = data;
      item.milestones = {};
      for (let i = 0; i < data.length; i++) {
        item.milestones[data[i].typeCode ?? data[i].type] = data[i];
      }
      node.setData(item);
      this.data = item;
      this.params.api.refreshCells({ rowNodes: [node] });
      //   let available = this.availableMilestones.filter(
      //     (x) => x.type == this.booking.movementType
      //   );
      //   this.milestoneContainer.containerMilestones = data;
      //   let findContainer = this.onlyShowActiveContainers
      //     ? this.activeContainers.find((x) => x.id == this.milestoneContainer.id)
      //     : this.allContainers.find((x) => x.id == this.milestoneContainer.id);
      //   available.forEach((x) => {
      //     let find = data.find((y) => y.type == x.value);
      //     if (find) {
      //       findContainer[x.value] = find;
      //     } else {
      //       findContainer[x.value] = null;
      //     }
      //   });
      //   // data.forEach(x=>{
      //   //   findContainer[x.type] = x
      //   // })
      this.addMilestoneModal = false;
      this.milestoneKey++;
      //   this.containerTableKey++;
    },
  },
};
</script>