<template>
  <div v-if="item">
    <v-tooltip top open-delay="500">
      <template v-slot:activator="{ on }">
        <v-chip small outlined style="font-size: 11px; border:none">
          <v-icon :color="statusColor(item)" v-on="on" small left>
            fiber_manual_record
          </v-icon>
          {{ getTextValue(item) }}
        </v-chip>
      </template>
      <span style="font-size: 12px">{{ getTextValue(item) }}</span>
    </v-tooltip>
  </div>
</template>
  
  <script>
export default {
  data: () => ({
    item: null,
    group: null,
    statuses: [
      { name: "Unallocated", value: null, icon: "warning", color: "orange darken-2" },
      { name: "Ghost", value: "ghost", icon: "category", color: "blue-grey" },
      { name: "Deleted", value: "error", icon: "error", color: "red" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "redPop" },
    ],
  }),
  mounted() {
    this.item = this.params.value;
  },
  methods: {
    getTextValue(status) {
      if (status) {
      return status
      } else {
        return "Unknown";
      }
    },
    statusColor(item) {
      if (item) {
        let find = this.statuses.find(
          (x) => x.name.toLowerCase() === item.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
  },
};
</script>
  
  <style>
</style>