<template>
  <div>
    <div  v-if="!group && item != null">
    <v-icon v-if="item" color="success" small>check_circle</v-icon>
    <v-icon v-else-if="item == false" color="red" small>cancel</v-icon>
  </div>
  <!-- <div  v-else-if="group">
    <v-icon v-if="item" color="success" class="mr-2">check_circle</v-icon>
    <v-icon v-else color="red" class="mr-2">cancel</v-icon>
    {{ group }}
  </div> -->
  </div>
</template>
<script>
export default {
  data: () => ({
    item: false,
    group: null,
  }),
  mounted() {
    this.item = this.params.value
      ? true
      : this.params.value != null
      ? false
      : null;
      if (this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
      if(this.group == null) {
        this.group = "None";
      }
    }
  },
  methods: {},
};
</script>