<template>
  <div>
    <v-btn color="grey" icon small @click="editContainerProduct()">
      <v-icon small>add_circle_outline</v-icon>
    </v-btn>

    <v-tooltip top v-for="product in productData" :key="product.id">
      <template v-slot:activator="{ on }">
        <v-chip
          small
          outlined
          style="border: none; font-size: 11px"
          @click="editContainerProduct(product)"
          v-on="on"
        >
          <v-icon left color="grey" x-small>fiber_manual_record</v-icon>
          {{ $Format.capitalizeFirstLetter(product.product.name) }}
          <v-icon v-if="product.industrial" color="blue-grey" small right
            >factory</v-icon
          >
          <v-icon v-if="product.organic" color="green" small right>spa</v-icon>
          <v-icon v-if="product.hazardous" color="orange" small right
            >warning</v-icon
          >
        </v-chip>
      </template>
      {{ $Format.capitalizeFirstLetter(product.product.name) }}
      <span v-if="product.grossWeight">
        - Gross: {{ product.grossWeight }}Kg</span
      >
      <span v-if="product.nettWeight"> - Nett: {{ product.nettWeight }}Kg</span>
      <span v-if="product.quantity">
        - {{ product.quantityType }}: {{ product.quantity }}</span
      >
      <span v-if="product.pallets"> - {{ product.pallets }} Pallets </span>
      <span v-if="product.batchNumber">
        - Batch: {{ product.batchNumber }}
      </span>
    </v-tooltip>
  
    <v-dialog
      v-model="containerProductModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="container">
        <ProductDialog
          @close="(containerProductModal = false)"
          :item="container.selectedProduct"
          @deleted="deleteSelectedContainerProduct"
          @chosenProduct="updateSelectedContainerProduct"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductDialog from "./ProductsDialog.vue";
export default {
  name: "ProductField",
  components: {
    ProductDialog,
  },
  data: () => ({
    booking: null,
    container: null,
    containerProductModal: false,
    item: null,
    productData: [],
    productModal: false,
  }),
  mounted() {
    this.item = this.params.valueFormatted
      ? this.params.valueFormatted.join(", ")
      : null;
    this.productData = this.params.data
      ? this.params.data.containerProducts
      : [];
    this.container = this.params.data;
    this.booking = this.params.booking();
  },
  methods: {
    async deleteSelectedContainerProduct() {
      let find = this.container.containerProducts.findIndex(
        (x) => x.id == this.container.selectedProduct.id
      );
      if (find > -1) {
        this.container.containerProducts.splice(find, 1);
      }
      let rowNode = this.params.api.getRowNode(this.params.node.id);
        if (rowNode) {
          rowNode.setData(this.container);
          this.params.api.refreshCells({ rowNodes: [rowNode], force: true });
        }
      this.containerProductModal = false;
      this.$message.success("Successfully removed product!");
    //   this.params.api.autoSizeColumns(["products"]);
    },

    editContainerProduct(product=null) {
      this.container.selectedProduct = product;
      this.containerProductModal = true;
    },
    async updateContainerProduct(item) {
      if (this.container) {
        this.container.containerProducts.push(item);
        let rowNode = this.params.api.getRowNode(this.params.node.id);
        if (rowNode) {
          rowNode.setData(this.container);
          this.params.api.refreshCells({ rowNodes: [rowNode], force: true });
        }
        this.productModal = false;
        // this.params.api.autoSizeColumns(["products"]);
      }
    },
    async updateSelectedContainerProduct(item) {
        console.log(item)
      this.containerProductModal = false;
      if (item.id) {
        await this.$API.updateContainerProduct(item);
        let findIndex = this.container.containerProducts.findIndex(
          (x) => x.id == item.id
        )
        this.container.containerProducts[findIndex] = item;
      } else {
        item = {
          ...item,
          bookingId: this.booking.id,
          bookingContainerId: this.container.id,
          productId: item.product.id,
        };
        let result = await this.$API.createContainerProduct(item);
        this.container.containerProducts.push({...result, product: item.product});
        console.log(this.container.containerProducts)
      }
      let rowNode = this.params.api.getRowNode(this.params.node.id);
        if (rowNode) {
          rowNode.setData(this.container);
          this.params.api.refreshCells({ rowNodes: [rowNode], force: true });
        }
      this.$message.success("Successfully updated container product!");
    //   this.params.api.autoSizeColumns(["products"]);
    },
  },
};
</script>